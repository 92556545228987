import React from 'react';
import styled from 'styled-components';
import SwiperCore from 'swiper';
import ArrowButton from 'components/ArrowButton';

interface Props {
  direction: 'next' | 'prev',
  instance: SwiperCore,
}

const NavBtn:React.FC<Props> = ({
  direction,
  instance,
}) => {
  const handleClick = () => {
    if (direction === 'prev') instance.slidePrev();
    if (direction === 'next') instance.slideNext();
  };
  const directionMap: { [x in typeof direction]: 'left' | 'right' } = {
    prev: 'left',
    next: 'right',
  };
  return (
    <NavBtnOuter onClick={handleClick}>
      <ArrowButton direction={directionMap[direction]} />
    </NavBtnOuter>
  );
};
export default NavBtn;

const NavBtnOuter = styled.div`
  padding: 5px;
`;
