import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

interface Props {
  direction?: 'left' | 'right',
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
}

const ArrowButton:React.FC<Props> = ({
  direction = 'right',
  onClick = () => {},
}) => {
  const ariaLabel = direction === 'left' ? 'prev navigation' : 'next navigation';
  return (
    <ArrowButtonOuter>
      <Button
        onClick={onClick}
        ariaLabel={ariaLabel}
        borderless
        type="clear"
      >
        <IconWrap className={direction === 'left' ? 'reversed' : ''}>
          <FontAwesomeIcon icon={faArrowCircleRight} />
        </IconWrap>
      </Button>
    </ArrowButtonOuter>
  );
};
export default ArrowButton;

const ArrowButtonOuter = styled.div``;
const IconWrap = styled.div`
  color: ${(p) => p.theme.palette.interactive};
  &.reversed {
    transform: rotate(-180deg);
  }
`;
