import React from 'react';
import styled from 'styled-components';
import SwiperCore, {
  A11y,
  Navigation,
  Keyboard,
  Pagination,
  Virtual,
  SwiperOptions,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useFromBreakpoint from 'src/hooks/useFromBreakpoint';
import NavBtn from './NavBtn';
import PaginationComponent from './Pagination';
import { Slide } from './types';

SwiperCore.use([A11y, Navigation, Keyboard, Pagination, Virtual]);


export interface Props {
  slides: Slide[],
  swiperOptions?: SwiperOptions,
  slidesPerPage?: number,
}

const Carousel:React.FC<Props> = ({
  slides,
  swiperOptions: swiperOptionsProp = {},
  slidesPerPage = 3,
}) => {
  const largerThanPhablet = useFromBreakpoint('phablet');
  const [instance, setInstance] = React.useState<SwiperCore>();
  const handleSwiperInit = React.useCallback((swiper: SwiperCore) => {
    setInstance(swiper);
  }, []);

  // fixes weird indexation bug when initially calling slideNext (etc)
  React.useEffect(() => {
    instance?.slideTo(slides.length, 0);
  }, [instance]); // eslint-disable-line react-hooks/exhaustive-deps

  if (slides.length <= slidesPerPage) {
    return (
      <Row>
        {slides.map(({ id, content }) => (
          <Col
            key={id}
            slidesLength={slides.length}
          >
            {content}
          </Col>
        ))}
      </Row>
    );
  }

  const swiperOptionsDefault: SwiperOptions = {
    loop: true,
    spaceBetween: 20,
    slidesPerView: largerThanPhablet
      ? Math.min(slidesPerPage, slides.length)
      : 1,
  };
  const mergedSwiperOptions: SwiperOptions = {
    ...swiperOptionsDefault,
    ...swiperOptionsProp,
  };

  return (
    <CarouselOuter>
      <Swiper
        {...mergedSwiperOptions}
        onSwiper={handleSwiperInit}
      >
        {slides.map(({ id, content }) => (
          <SwiperSlide key={id}>
            <SlideInner>{content}</SlideInner>
          </SwiperSlide>
        ))}
      </Swiper>
      {instance && (
        <NavSection>
          <PaginationWrap>
            <PaginationComponent instance={instance} slidesAmount={slides.length} />
          </PaginationWrap>
          <NavButtonsWrap>
            <NavBtn direction="prev" instance={instance} />
            <NavBtn direction="next" instance={instance} />
          </NavButtonsWrap>
        </NavSection>
      )}
    </CarouselOuter>
  );
};
export default Carousel;

const CarouselOuter = styled.div`
  position: relative;
  > .swiper-container {
    padding-bottom: 50px;
  }
`;
const NavSection = styled.div`
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    display: flex;
    align-items: center;
  }
`;
const NavButtonsWrap = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
`;
const PaginationWrap = styled.div`
  flex: 1 0 auto;
`;
const SlideInner = styled.div``;

const Row = styled.div`
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    display: flex;
    margin: -10px;
  }
`;
const Col = styled.div<{ slidesLength: number }>`
  padding: 10px;
  flex: 1 1 ${(p) => 100 / p.slidesLength}%;
`;
