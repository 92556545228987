import { nanoid } from 'nanoid';
import React from 'react';
import styled from 'styled-components';
import SwiperCore from 'swiper';

interface Props {
  instance: SwiperCore,
  slidesAmount: number,
}

const Pagination:React.FC<Props> = ({ instance, slidesAmount }) => {
  const [activeIndex, setActiveIndex] = React.useState(instance.realIndex);
  const slideIds = React.useRef(Array.from({ length: slidesAmount }, () => nanoid()));

  React.useEffect(() => {
    const handleChange = (i: SwiperCore) => setActiveIndex(i.realIndex);
    instance?.on('slideChange', handleChange);
    return () => instance?.off('slideChange', handleChange);
  }, [instance]);

  return (
    <PaginationOuter>
      <PipsWrap>
        {slideIds.current.map((id, i) => (
          <Pip key={id} className={i === activeIndex ? 'active' : ''} />
        ))}
      </PipsWrap>
    </PaginationOuter>
  );
};
export default Pagination;

const PaginationOuter = styled.div``;
const PipsWrap = styled.div`
  display: flex;
  background-color: #eee;
`;
const Pip = styled.div`
  flex: 1 0 auto;
  height: 3px;
  background-color: #eee;
  transform: scaleX(0);
  transition: transform 0.3s, background-color 0.3s;
  &.active {
    background-color: #333;
    transform: scaleX(1);
  }
`;
